<template>
  <Leaderboard/>
</template>

<script>
import Leaderboard from '../components/Leaderboard.vue'

export default {
  components: {
    Leaderboard
  }
}
</script>
